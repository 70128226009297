import { config } from "@moe/priv/config";
import { Profile, ProfileSelf, ProfileUpdate } from "@moe/priv/model/profile";
import { TablesInsert, TablesUpdate } from "@moe/priv/types/sb-types";
import { removeImage, uploadImage } from "@moe/priv/utils";
import { QueryData } from "@supabase/supabase-js";
import { sb } from "@web/lib/supabase";
import { ID } from "./base";

type R = Profile;

type I = ProfileUpdate;

type U = ProfileUpdate;

namespace Q {
  export namespace All {
    export const selector = `
      id, 
      username, 
      bio, 
      created_at, 
      avatar_file_name, 
      banner_file_name, 
      follower_count, 
      following_count, 
      message_count, 
      followers:follows!follows_followed_id_fkey(follower_id),
      following:follows!follows_follower_id_fkey(followed_id),
      badges
    `;
    const builder = sb.from("profiles").select(selector);
    export type Builder = typeof builder;
    export type Data = QueryData<typeof builder>[number];
  }
  export namespace Self {
    export const selector = `${All.selector}, profile_settings!inner(*), subscriptions!subscriptions_subscriber_fkey(kind, tier, expiry)`;
    const builder = sb.from("profiles").select(selector);
    export type Builder = typeof builder;
    export type Data = QueryData<typeof builder>[number];
  }
}

export class ProfileAccessor {
  private readonly userID: string | undefined;

  constructor({ userID }: { userID?: string } = {}) {
    this.userID = userID;
  }

  /**
   * Retrieves the profile of the current user.
   *
   * @returns A promise that resolves to the profile of the current user.
   */
  async getSelf(): Promise<ProfileSelf> {
    if (!this.userID) throw new Error("User is not signed in.");
    const { data, error } = await sb.from("profiles").select(Q.Self.selector).eq("id", this.userID).single();
    if (error) throw error;

    return this.processSelfQuery(data);
  }

  /**
   * Retrieves a profile by username.
   *
   * @param username - The username of the profile to retrieve.
   * @returns A promise that resolves to the profile with the specified username.
   */
  async getByUsername(username: string): Promise<R> {
    const { data, error } = await sb.from("profiles").select(Q.All.selector).eq("username", username).single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  /**
   * Retrieves a profile or a list of profiles by their ID.
   *
   * @param id - The ID or an array of IDs of the profiles to retrieve.
   * @returns A promise that resolves to the profiles or an array of profiles with the specified ID(s).
   */
  async getByID(id: ID): Promise<R>;
  async getByID(id: ID[]): Promise<R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]> {
    const q = sb.from("profiles").select(Q.All.selector);
    if (Array.isArray(id)) {
      const { data, error } = await q.in("id", id);
      if (error) throw error;
      return this.processAllQuery(data);
    }

    const { data, error } = await q.eq("id", id).single();
    if (error) throw error;

    return this.processAllQuery(data);
  }

  /**
   * Updates the profile of the current user.
   *
   * @param value - The data to update the profile with.
   */
  async updateSelf(value: U): Promise<void> {
    if (!this.userID) throw new Error("User is not signed in.");

    let avatarFileName: string | undefined;
    let bannerFileName: string | undefined;
    if (value.avatar) avatarFileName = await uploadImage(value.avatar, sb);
    if (value.banner) bannerFileName = await uploadImage(value.banner, sb);

    const { data: old, error: oldError } = await sb
      .from("profiles")
      .select("id, avatar_file_name, banner_file_name")
      .eq("id", this.userID)
      .single();
    if (oldError) throw oldError;

    if (old.avatar_file_name && avatarFileName) await removeImage(old.avatar_file_name, sb);
    if (old.banner_file_name && bannerFileName) await removeImage(old.banner_file_name, sb);

    // Update profile
    const profileValue: TablesUpdate<"profiles"> = {
      username: value.username,
      bio: value.bio,
      avatar_file_name: avatarFileName,
      banner_file_name: bannerFileName
    };

    const { error: profileError } = await sb.from("profiles").update(profileValue).eq("id", this.userID);
    if (profileError) throw profileError;

    // Update profile settings
    const settingsValue: TablesUpdate<"profile_settings"> = {
      nsfw_ok: value?.settings?.nsfwOK,
      message_avatar_size: value?.settings?.messageAvatarSize,
      message_avatar_shape: value?.settings?.messageAvatarShape,
      blacklisted_tags: value?.settings?.blacklistedTags,
      confirm_delete: value?.settings?.confirmDelete,
      blockquote_mode: value?.settings?.blockquoteMode
    };

    const { error: settingsError } = await sb.from("profile_settings").update(settingsValue).eq("id", this.userID);
    if (settingsError) throw settingsError;

    const { error } = await sb.from("profiles").select(Q.Self.selector).eq("id", this.userID);
    if (error) throw error;
  }

  /**
   * Follows the specified user.
   *
   * @param value - The user to follow.
   * @returns A promise that resolves when the follow operation is completed.
   */
  async follow(value: I): Promise<void> {
    if (!this.userID || !value.id) throw new Error("User is not signed in.");
    const insertData = {
      follower_id: this.userID,
      followed_id: value.id
    } satisfies TablesInsert<"follows">;

    const { error } = await sb.from("follows").insert(insertData).select().single();
    if (error) throw error;
  }

  /**
   * Unfollows a user.
   *
   * @param value - The user to unfollow.
   * @returns A promise that resolves when the user is unfollowed successfully.
   */
  async unfollow(value: I): Promise<void> {
    if (!this.userID || !value.id) throw new Error("User is not signed in.");
    const { error } = await sb.from("follows").delete().eq("follower_id", this.userID).eq("followed_id", value.id);
    if (error) throw error;
  }

  /**
   * Checks if the current user is following a specific profile.
   *
   * @param username - The username of the profile to check.
   * @returns A promise that resolves to a boolean indicating whether the current user is following the specified profile.
   */
  async getFollowStatus(username: string): Promise<boolean> {
    if (!this.userID) throw new Error("User is not signed in.");

    const { data: followedData, error: FollowedError } = await sb
      .from("profiles")
      .select("id")
      .eq("username", username)
      .single();

    if (FollowedError) throw new Error("FollowedError");
    if (!followedData) throw new Error("Profile not found");

    const { data: FollowStatusData, error: followStatusError } = await sb
      .from("follows")
      .select("id")
      .eq("follower_id", this.userID)
      .eq("followed_id", followedData.id)
      .maybeSingle();
    if (followStatusError) throw new Error("followStatusError");
    return !!FollowStatusData;
  }

  /**
   * Processes the raw response data from the Q.Self query and return a ProfileSelf (R)esource.
   *
   * @param value - The raw data from the database from the Q.Self query.
   * @returns A ProfileSelf (R)esource object
   */
  private async processSelfQuery(value: Q.Self.Data): Promise<ProfileSelf> {
    const profile = await this.processAllQuery(value);
    // Only return subscriptions that are active
    const subscriptions = value.subscriptions.filter((s) => s.expiry === null || s.expiry > new Date().toISOString());
    const profileSelf: ProfileSelf = {
      ...profile,
      settings: {
        nsfwOK: value.profile_settings.nsfw_ok,
        messageAvatarSize: value.profile_settings.message_avatar_size,
        messageAvatarShape: value.profile_settings.message_avatar_shape,
        confirmDelete: value.profile_settings.confirm_delete,
        blacklistedTags: value.profile_settings.blacklisted_tags,
        blockquoteMode: value.profile_settings.blockquote_mode
      },
      subscriptions
    };

    return profileSelf;
  }

  /**
   * Processes the raw response data from persistent storage and returns a Profile (R)esource.
   *
   * @param value - The raw data from the database, either a single object or an array of objects.
   * @returns A Profile (R)esource object.
   */
  private async processAllQuery(value: Q.All.Data): Promise<R>;
  private async processAllQuery(value: Q.All.Data[]): Promise<R[]>;
  private async processAllQuery(value: Q.All.Data | Q.All.Data[]): Promise<R | R[]>;
  private async processAllQuery(value: Q.All.Data | Q.All.Data[]): Promise<R | R[]> {
    if (Array.isArray(value)) return Promise.all(value.map((r) => this.processAllQuery(r)));

    let avatar: string | undefined;
    let banner: string | undefined;
    if (value.avatar_file_name) {
      avatar = sb.storage.from(config.storage.publicImagesBucket).getPublicUrl(value.avatar_file_name).data.publicUrl;
    }
    if (value.banner_file_name) {
      banner = sb.storage.from(config.storage.publicImagesBucket).getPublicUrl(value.banner_file_name).data.publicUrl;
    }

    const followers: string[] = value.followers?.map((f: { follower_id: string }) => f.follower_id) || [];
    const following: string[] = value.following?.map((f: { followed_id: string }) => f.followed_id) || [];

    const isFollowing = await this.getFollowStatus(value.username || "unknown");

    return {
      id: value.id,
      username: value.username || "unknown",
      bio: value.bio,
      createdAt: value.created_at,
      avatar: avatar,
      banner: banner,
      followerCount: value.follower_count,
      followingCount: value.following_count,
      messageCount: value.message_count,
      followers: followers,
      following: following,
      isFollowing: isFollowing,
      badges: value.badges
    };
  }
}
