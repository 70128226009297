import { QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
import { Tip } from "./tip";

interface TipBubbleProps {
  text: string;
  className?: string;
}

/**
 * Renders a (?) bubble component that displays a tooltip on hover or on click.
 * This is useful for providing additional guidance in confusing parts of the app.
 */
export function TipBubble({ text, className }: TipBubbleProps) {
  return (
    <Tip text={text} className={className}>
      <button className="bg-transparent p-2">
        <QuestionMarkCircleIcon className="text-tx-tertiary size-4" />
      </button>
    </Tip>
  );
}
