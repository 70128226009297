import { Dialog, DialogContent } from "@moe/oss/ui/dialog";
import { ModalConfig } from "@moe/priv/types/types";
import { useRouter } from "@tanstack/react-router";
import { isDesktopStatic } from "@web/lib/utils";
import EventEmitter from "events";
import { useCallback, useEffect, useState } from "react";
import { Sheet } from "react-modal-sheet";
import { RemoveScroll } from "react-remove-scroll";

export const modalEE = new EventEmitter();

export function ModalProvider() {
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState<ModalConfig>();
  const router = useRouter();

  const createModalHandler = useCallback(
    (newConfig: any) => {
      if (config) {
        setConfig(newConfig);
        setOpen(true);
      } else {
        setOpen(false);
        setTimeout(() => {
          setConfig(newConfig);
          setOpen(true);
        }, 200);
      }
    },
    [config]
  );

  useEffect(() => {
    const unsubscribe = router.history.subscribe(() => {
      modalEE.emit("closeModal");
    });

    return () => {
      unsubscribe();
    };
  }, [router.history]);

  const closeModalHandler = useCallback(() => {
    setOpen(false);
    setConfig(undefined);
  }, []);

  useEffect(() => {
    const closeModalHandler = () => {
      setOpen(false);
      setConfig(undefined);
    };
    modalEE.on("createModal", createModalHandler);
    modalEE.on("closeModal", closeModalHandler);

    return () => {
      modalEE.off("createModal", createModalHandler);
      modalEE.off("closeModal", closeModalHandler);
    };
  }, [createModalHandler, closeModalHandler]);

  if (isDesktopStatic) {
    return (
      <>
        <Dialog
          open={open}
          onOpenChange={(o) => {
            setOpen(o);
            if (!o) setConfig(undefined);
          }}
        >
          <DialogContent
            onOpenAutoFocus={(e) => e.preventDefault()}
            className="border-line w-fit max-w-none border p-0 outline-none"
            onInteractOutside={(e) => {
              if (!config) return;
              if (config.onInteractOutside) config.onInteractOutside(e);
            }}
          >
            {config?.content}
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Sheet
        isOpen={open}
        disableScrollLocking={true}
        style={{ willChange: "transform" }}
        onCloseEnd={() => {
          setConfig(undefined);
          setOpen(false);
        }}
        tweenConfig={{
          ease: "easeOut",
          duration: 0.2
        }}
        onClose={() => {
          setOpen(false);
        }}
        detent="content-height"
      >
        <Sheet.Container className="bg-float">
          <Sheet.Header>
            <div className="bg-float flex w-full items-center justify-center pb-4 pt-4">
              <div className="bg-muted h-2 w-20 rounded-sm"></div>
            </div>
          </Sheet.Header>
          <RemoveScroll enabled={open}>
            <Sheet.Content className="bg-float overscroll-contain" disableDrag={config?.headerDragOnly === true}>
              <Sheet.Scroller>{config?.content}</Sheet.Scroller>
            </Sheet.Content>
          </RemoveScroll>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => {
            setConfig(undefined);
            setOpen(false);
          }}
        />
      </Sheet>
    </>
  );
}
