import { ProfileSettings } from "@moe/priv/model/profile";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { settingsQK } from "@web/hooks/use-settings-query";
import { useAppContext } from "@web/route-services/root/AppContext";
import { toast } from "sonner";
interface Props {
  showSuccessToast?: boolean;
}

/**
 * A mutation to update the user's preferences options.
 */
export function useSettingsMutation({ showSuccessToast = true }: Props = {}) {
  const { access } = useAppContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (settings: Partial<ProfileSettings>) => {
      await access.profile.updateSelf({
        settings
      });
    },
    onMutate: async (newSettings) => {
      await queryClient.cancelQueries({ queryKey: [settingsQK] });
      const prev = queryClient.getQueryData([settingsQK]);
      queryClient.setQueryData([settingsQK], (old: ProfileSettings | undefined) => ({
        ...old,
        ...newSettings
      }));
      return { prev };
    },
    onError: (_err, _newValue, context) => {
      queryClient.setQueryData([settingsQK], context?.prev);
      toast.error("Error updating preference settings");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [settingsQK] });
    },
    onSuccess: () => {
      if (showSuccessToast) {
        toast.success("Settings updated!", {
          duration: 2000
        });
      }
    }
  });
}
