import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@web/routes/__root";
import { z } from "zod";

export const apiSettingsQK = "apiSettings";
export const apiSettingsSchema = z.object({
  provider: z.enum(["anime.gf", "proxy"]),
  apiKey: z.string(),
  baseURL: z.string(),
  model: z.string(),
  customInstructions: z.string(),
  maxContextTokens: z.number()
});
export type APISettings = z.infer<typeof apiSettingsSchema>;
const defaultSettings: APISettings = {
  provider: "anime.gf" as const,
  apiKey: "",
  baseURL: "",
  model: "dynamic",
  customInstructions: "",
  maxContextTokens: 6000
};

/**
 * Retrieves the API settings from localStorage, or returns a default configuration if none is found.
 * @returns The current API settings
 */
export const useAPISettings = () => {
  const { data } = useQuery(
    {
      queryKey: [apiSettingsQK],
      queryFn: () => {
        const stored = localStorage.getItem(apiSettingsQK);
        if (!stored) return defaultSettings;
        const parsed = apiSettingsSchema.safeParse(JSON.parse(stored));
        return parsed.success ? parsed.data : defaultSettings;
      }
    },
    queryClient
  );
  return data || defaultSettings;
};
