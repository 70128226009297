import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster"
      toastOptions={{
        classNames: {
          toast: "bg-float text-tx-primary border-line shadow-lg",
          description: "text-tx-secondary",
          actionButton: "bg-primary text-tx-primary",
          cancelButton: "text-tx-secondary",
          closeButton: "!bg-float"
        }
      }}
      {...props}
    />
  );
};

export { Toaster };
