import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@moe/oss/ui/card";
import { Switch } from "@moe/oss/ui/switch";
import { TipBubble } from "@moe/priv/components/tip-bubble";
import { DialogConfig } from "@moe/priv/types/types";
import { Popover, PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { Sketch } from "@uiw/react-color";
import { useSettingsMutation } from "@web/hooks/use-settings-mutation";
import { useAppContext } from "@web/route-services/root/AppContext";
import { useState } from "react";

export const cosmeticQK = "appearance";

export interface CosmeticSettings {
  userMessageColor: string;
  characterMessageColor: string;
  userMessageQuotesColor: string;
  characterMessageQuotesColor: string;
  userMessageItalicsColor: string;
  characterMessageItalicsColor: string;
}
/**
 * Maps appearance settings variables to CSS variables.
 */
export const cosmeticCSSVarMap = {
  userMessageColor: "--chat-user-message-text",
  characterMessageColor: "--chat-character-message-text",
  userMessageQuotesColor: "--chat-user-message-text-quotes",
  characterMessageQuotesColor: "--chat-character-message-text-quotes",
  userMessageItalicsColor: "--chat-user-message-text-italics",
  characterMessageItalicsColor: "--chat-character-message-text-italics"
} as const;

const defaultSettings: CosmeticSettings = {
  userMessageColor: "hsla(225, 10.6%, 96%, 1)",
  characterMessageColor: "hsla(225, 10.6%, 98%, 1)",
  userMessageQuotesColor: "hsla(225, 5.6%, 80%, 1)",
  characterMessageQuotesColor: "hsla(225, 10.6%, 75%, 1)",
  userMessageItalicsColor: "hsla(225, 5.6%, 70%, 1)",
  characterMessageItalicsColor: "hsla(225, 10.6%, 59%, 1)"
};

/**
 * This modal all allows the user to configure custom chat style settings.
 * The API settings are stored in the user's local storage and used in the chat service to generate responses.
 */
export function CosmeticSettingsModal() {
  const { createDialog, closeModal, createModal } = useAppContext();

  const [preferences, setPreferences] = useState<CosmeticSettings>(() => {
    const cached = localStorage.getItem(cosmeticQK);
    return cached ? JSON.parse(cached) : defaultSettings;
  });

  const settingsMutation = useSettingsMutation({ showSuccessToast: false });
  const { settings } = useAppContext();

  const updatePreference = (key: keyof CosmeticSettings, value: string) => {
    const newPreferences = { ...preferences, [key]: value };
    setPreferences(newPreferences);
    localStorage.setItem(cosmeticQK, JSON.stringify(newPreferences));
    document.documentElement.style.setProperty(cosmeticCSSVarMap[key], value);
  };

  const resetChatColors = () => {
    localStorage.removeItem(cosmeticQK);
    setPreferences(defaultSettings);

    Object.entries(defaultSettings).forEach(([key, value]) => {
      const cssVar = cosmeticCSSVarMap[key as keyof CosmeticSettings];
      document.documentElement.style.setProperty(cssVar, value);
    });
  };

  /**
   * Creates a dialog to confirm the reset action.
   * After the dialog is closed, it restores the current modal
   */
  const resetButtonHandler = () => {
    const config: DialogConfig = {
      title: "Reset Colors",
      description: "Are you sure you want to reset all chat colors to default?",
      actionLabel: "Reset",
      onAction: () => {
        resetChatColors();
        createModal({ content: <CosmeticSettingsModal />, headerDragOnly: true });
      },
      onCancel: () => createModal({ content: <CosmeticSettingsModal />, headerDragOnly: true })
    };
    closeModal();
    createDialog(config);
  };

  return (
    <Card className="text-tx-primary bg-float scroll-primary max-h-[75vh] w-full overflow-y-auto border-none md:w-[32rem] md:border">
      <CardHeader>
        <CardTitle>Chat Appearance</CardTitle>
        <CardDescription>Customize the colors of your chat messages. ٩(◑ᴗ◑๑)</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-10">
          <div className="flex justify-between gap-6">
            <div className="flex flex-col gap-1.5">
              <span className="text-base font-semibold">User</span>
              <div className="flex gap-4">
                <ColorPicker
                  label="Base"
                  color={preferences.userMessageColor}
                  onChange={(color) => updatePreference("userMessageColor", color)}
                />
                <ColorPicker
                  label="Italic"
                  color={preferences.userMessageItalicsColor}
                  onChange={(color) => updatePreference("userMessageItalicsColor", color)}
                />
                <ColorPicker
                  label="Quote"
                  color={preferences.userMessageQuotesColor}
                  onChange={(color) => updatePreference("userMessageQuotesColor", color)}
                />
              </div>
            </div>

            <div className="flex flex-col gap-1.5">
              <span className="text-base font-semibold">Character</span>
              <div className="flex gap-4">
                <ColorPicker
                  label="Base"
                  color={preferences.characterMessageColor}
                  onChange={(color) => updatePreference("characterMessageColor", color)}
                />
                <ColorPicker
                  label="Italic"
                  color={preferences.characterMessageItalicsColor}
                  onChange={(color) => updatePreference("characterMessageItalicsColor", color)}
                />
                <ColorPicker
                  label="Quote"
                  color={preferences.characterMessageQuotesColor}
                  onChange={(color) => updatePreference("characterMessageQuotesColor", color)}
                />
              </div>
            </div>
          </div>
          <button
            onClick={resetButtonHandler}
            className="text-tx-tertiary hover:text-tx-primary text-sm font-semibold transition-colors"
          >
            Reset Colors
          </button>
        </div>

        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center gap-1">
            <label className="mb-1 block font-medium">Block Quote Mode</label>
            <TipBubble text={`Text quoted "like this" will be rendered as block.`} />
          </div>
          <Switch
            checked={settings?.blockquoteMode}
            onCheckedChange={(isChecked) => {
              settingsMutation.mutate({ blockquoteMode: isChecked });
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
}

interface ColorPickerProps {
  label: string;
  color: string;
  onChange: (color: string) => void;
}

function ColorPicker({ label, color, onChange }: ColorPickerProps) {
  return (
    <div className="group flex flex-col items-center gap-1">
      <span className="group-hover:text-tx-secondary group-active:text-tx-tertiary text-sm transition-colors duration-300 ease-out">
        {label}
      </span>
      <Popover>
        <PopoverTrigger>
          <div
            className="border-line z-50 h-8 w-8 cursor-pointer rounded-md border"
            style={{ backgroundColor: color }}
          />
        </PopoverTrigger>
        <PopoverContent className="bg-float w-fit p-0">
          <Sketch
            color={color}
            disableAlpha={true}
            onChange={(color) => {
              onChange(color.hex);
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
