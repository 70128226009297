import { Character, CharacterInsert, CharacterService, CharacterUpdate } from "@moe/priv/model/character";
import { SharedCharacterAccessor } from "@moe/priv/shared-accessor/character";
import { getDummySB } from "@moe/priv/utils";
import { QueryData } from "@supabase/supabase-js";
import { sb } from "@web/lib/supabase";
import { Client } from "@web/lib/trpc";
import { DeepPartial } from "react-hook-form";

type R = Character;
type I = CharacterInsert;
type U = CharacterUpdate;

namespace Q {
  export namespace Count {
    export const selector = [`id`, { count: "planned", head: true }] as const;
    const builder = () =>
      getDummySB()
        .from("characters")
        .select(...selector);
    export type Data = QueryData<ReturnType<typeof builder>>[number];
  }
}

type GetPageOptions = CharacterService.GetPageOptions;
type GetLastPageOptions = CharacterService.GetLastPageOptions;
type UpdateFormCharacter = CharacterService.UpdateFormCharacter;

interface Constructor {
  userID?: string;
  trpc: Client;
}

/**
 * The CharacterAccessor class extends the SharedCharacterAccessor with frontend-specific methods.
 *
 * @class CharacterAccessor
 * @extends {SharedCharacterAccessor}
 */
export class CharacterAccessor extends SharedCharacterAccessor {
  private trpc: Client;

  constructor({ userID, trpc }: Constructor) {
    super({ userID, sb });
    this.trpc = trpc;
  }

  async getForUpdate(id: number): Promise<UpdateFormCharacter> {
    return await this.trpc.character.getForUpdate.query(id);
  }

  /**
   * @outdated This should be placed in the trpc backend instead.
   * Get the last page number for the explore page pagination.
   * Private and deleted characters are filtered out.
   */
  async getLastPageNumber(options?: DeepPartial<GetLastPageOptions>): Promise<number> {
    const opt = this.normalizeGetPageOptions(options) as GetLastPageOptions;
    const q = sb
      .from("characters")
      .select(...Q.Count.selector)
      .is("is_private", false)
      .is("deleted_at", null);

    if (!opt.filter.nsfwOK) q.is("is_nsfw", false);

    const searchText = opt.filter.searchText;
    if (searchText) {
      q.or(`title.ilike.%${searchText}%,tagline.ilike.%${searchText}%,name.ilike.%${searchText}%`);
    }

    const { count, error } = await q;
    if (error) throw error;

    // Count is inexact so we subtract 1 to be safe
    return Math.ceil((count || 0) / opt.pageSize) - 1;
  }

  async getSimilar(id: number, isNsfw: boolean): Promise<R[]> {
    return await this.trpc.character.getSimilar.query({ characterId: id, nsfwOK: isNsfw });
  }

  async getRecommended(isNsfw: boolean): Promise<R[]> {
    return await this.trpc.character.getRecommended.query({ nsfwOK: isNsfw });
  }

  async getPage(options?: DeepPartial<GetPageOptions>): Promise<R[]> {
    const opt = this.normalizeGetPageOptions(options);
    return await this.trpc.character.getPage.query(opt);
  }

  /**
   * Get *all* tags from the db.
   * Very expensive operation, please cache this if you use it.
   */
  async getTags(): Promise<string[]> {
    return await this.trpc.character.getAllTags.query();
  }

  async getRecents(): Promise<R[]> {
    return await this.trpc.character.getRecents.query();
  }
}
