import { useKeyHeld } from "@moe/priv/hooks/use-key-held";
import { desktopMediaQuery, useMediaQuery } from "@moe/priv/hooks/use-media-query";
import { Profile, ProfileSettings } from "@moe/priv/model/profile";
import { DialogConfig, ModalConfig } from "@moe/priv/types/types";
import { Session } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { AccountAccessor } from "@web/accessor/account";
import { CharacterAccessor } from "@web/accessor/character";
import { ChatAccessor } from "@web/accessor/chat";
import { MessageAccessor } from "@web/accessor/message";
import { PersonaAccessor } from "@web/accessor/persona";
import { ProfileAccessor } from "@web/accessor/profile";
import { APISettings, useAPISettings } from "@web/hooks/use-api-settings";
import { useSettingsQuery } from "@web/hooks/use-settings-query";
import { config } from "@web/lib/config";
import { sb } from "@web/lib/supabase";
import { client } from "@web/lib/trpc";
import { modalEE } from "@web/route-services/root/ModalProvider";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";

export interface Access {
  account: AccountAccessor;
  chat: ChatAccessor;
  character: CharacterAccessor;
  message: MessageAccessor;
  persona: PersonaAccessor;
  profile: ProfileAccessor;
}

export interface AppContextProps {
  createDialog: (config: DialogConfig) => void;
  createModal: (config: ModalConfig) => void;
  closeModal: () => void;
  session: Session | undefined;
  profile: Profile | undefined;
  settings: ProfileSettings | undefined;
  apiSettings: APISettings;
  access: Access;
  tags: string[];
  /**
   * Detects if the user's screensize is large enough to be considered a desktop screen
   * Useful for *rendering* UI conditionally
   */
  isDesktopScreen: boolean;
  /**
   * Detects if the user's device is touch enabled
   * Useful for *processing inputs* conditionally
   */
  isTouchDevice: boolean;
  isDebug: boolean;
  doFocusExploreRef: React.MutableRefObject<boolean>;
}

const Context = createContext<AppContextProps>({} as AppContextProps);

export const AppContextProvider = ({
  setAlertConfig,
  children
}: {
  setAlertConfig: React.Dispatch<React.SetStateAction<DialogConfig | undefined>>;
  children: React.ReactNode;
}) => {
  const doFocusExploreRef = useRef<boolean>(false);
  const [isDebug, setIsDebug] = useState(() => {
    // Toggle dev tools in prod
    const saved = localStorage.getItem("isDebugMode");
    const parsed = saved ? (JSON.parse(saved) as boolean) : false;
    // Dev tools is enabled when either:
    // - We are in a development environment
    // - Or we're in prod and we have set the isDebugMode flag
    return parsed || config.env.dev;
  });

  const [session, setSession] = useState<Session>();

  // Attach .debug() to the global window object
  // this allows us to toggle dev tools in prod with window.debug()
  useEffect(() => {
    window.debug = () => {
      setIsDebug(!isDebug);
      localStorage.setItem("isDebugMode", JSON.stringify(!isDebug));
    };
    return () => {
      delete window.debug;
    };
  }, [isDebug]);

  // Update session information
  useEffect(() => {
    /**
     * Our Chrome extension can't directly access the anime.gf's local storage to grab the auth token.
     * However, cookies are easily accessible.
     * @see https://developer.chrome.com/docs/extensions/reference/api/cookies#method-get
     *
     * Thus, whenever the session updates, we also sync this cookie so our extension could grab it.
     * nomnomnomnomnom
     */
    const syncCookie = async (session: Session | null) => {
      if (session?.access_token) {
        document.cookie = `sbAuthToken=${session.access_token}; path=/; secure; samesite=strict`;
        return;
      }
      document.cookie = "sbAuthToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    };

    sb.auth.getSession().then(({ data: { session } }) => {
      setSession(session ?? undefined);
      syncCookie(session);
    });

    const {
      data: { subscription }
    } = sb.auth.onAuthStateChange((_event, session) => {
      setSession(session ?? undefined);
      syncCookie(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  const isShiftHeld = useKeyHeld("Shift");
  const isDesktopScreen = useMediaQuery(desktopMediaQuery);
  const settings = useSettingsQuery(session);
  const apiSettings = useAPISettings();

  const { data: tags = [] } = useQuery({
    queryKey: ["getTags"],
    queryFn: async () => {
      return access.character.getTags();
    },
    // 1 Day
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    meta: { persist: true }
  });
  const access = useMemo(() => {
    const userID = session?.user.id;
    const account = new AccountAccessor({ userID, trpc: client });
    const chat = new ChatAccessor({ userID, trpc: client });
    const character = new CharacterAccessor({ userID, trpc: client });
    const message = new MessageAccessor({ userID, trpc: client });
    const persona = new PersonaAccessor({ userID });
    const profile = new ProfileAccessor({ userID });
    return {
      account,
      chat,
      character,
      message,
      persona,
      profile
    };
  }, [session?.user.id]);

  const { data: profile } = useQuery({
    queryKey: ["profile", session?.user.id],
    queryFn: async () => {
      const id = session?.user.id;
      if (!id) return;
      return await access.profile.getByID(id);
    },
    enabled: !!session?.user.id
  });

  const isTouchDevice = useMemo(() => {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  const appContextValue: AppContextProps = {
    createDialog: (config) => {
      if (isShiftHeld) {
        config.onAction();
        return;
      }
      setAlertConfig(config);
    },
    createModal: (config) => {
      modalEE.emit("createModal", config);
    },
    closeModal: () => {
      modalEE.emit("closeModal");
    },
    session,
    profile,
    settings,
    apiSettings,
    access,
    tags,
    isDesktopScreen,
    isTouchDevice,
    isDebug,
    doFocusExploreRef
  };

  return <Context.Provider value={appContextValue}>{children}</Context.Provider>;
};

export function useAppContext() {
  const context = useContext(Context);
  if (context === undefined) throw new Error("useApp must be used within an App Provider");
  return context;
}
