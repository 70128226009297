import { Enums } from "@moe/priv/types/sb-types";
import { z } from "zod";

/**
 * The core type definitions for a "character" in the app.
 */
export const characterConfig = {
  nameMinChars: 1,
  nameMaxChars: 256,

  titleMinChars: 1,
  titleMaxChars: 256,

  taglineMinChars: 8,
  taglineMaxChars: 8192,

  descriptionMinChars: 0,
  descriptionMaxChars: 8192,

  scenarioMinChars: 0,
  scenarioMaxChars: 8192,

  greetingMinChars: 1,
  greetingMaxChars: 4096,

  alternativeGreetingsMaxCount: 16,

  definitionsMinChars: 0,
  definitionsMaxChars: 8192,

  msgExamplesMinChars: 0,
  msgExamplesMaxChars: 4096,

  avatarWidth: 400,
  avatarHeight: 600,
  avatarMaxFileSizeBytes: 3e6,

  bannerWidth: 1500,
  bannerHeight: 500,
  bannerMaxFileSizeBytes: 3e6,

  tagsMaxCount: 32,
  tagsRegex: /^[a-zA-Z0-9-_]+$/,
  tagsCleanRegex: /[^a-zA-Z0-9-_]/g
};

export interface Character {
  id: number;
  title: string;
  tagline: string;
  tags?: string[];
  messageCount: number;
  likeCount: number;
  isLiked: boolean;
  name: string;
  greeting: string;
  scenario: string;
  alternativeGreetings: string[];
  avatar: string;
  banner?: string;
  isNSFW: boolean;
  isForcedNSFW: boolean;
  isPrivate: boolean;
  isDeleted: boolean;
  createdBy: string;
  externalCreatedBy?: string;
  createdAt: string;
  updatedAt?: string;
}

export interface CharacterInsert {
  name: string;
  title: string;
  tagline: string;
  description: string;
  scenario?: string;
  greeting: string;
  alternativeGreetings?: string[];
  definitions?: string;
  messageExamples?: string;
  avatar: File;
  banner?: File;
  isNSFW?: boolean;
  isPrivate?: boolean;
  externalKind?: Enums<"cardexternalkind">;
  externalCreatedBy?: string;
  tags?: string[];
}

export interface CharacterUpdate {
  id: number;
  title?: string;
  tagline?: string;
  name?: string;
  description?: string;
  scenario?: string;
  greeting?: string;
  alternativeGreetings?: string[];
  definitions?: string;
  messageExamples?: string;
  avatar?: File;
  banner?: File;
  isNSFW?: boolean;
  isPrivate?: boolean;
  deletedAt?: string;
}

export namespace CharacterService {
  export interface TimeRange {
    start: string | undefined;
    end: string | undefined;
  }

  export const periodSchema = z.enum(["day", "week", "month", "year", "all"]);
  export type Period = z.infer<typeof periodSchema>;
  export const orderSchema = z.enum(["asc", "desc"]);
  export type Order = z.infer<typeof orderSchema>;

  /**
   * An enum schema that defines the available ranking options for character search and sorting.
   * The possible values are:
   * - "message_count": Rank by the number of messages that has been sent to the character
   * - "hotness": Rank by the "hotness" score of the character.
   * - "recency": Rank by the recency of the character's creation.
   * - "liked": Rank only like characters liked by the current user.
   * - "random": Rank the characters in a random order.
   */
  export const rankingSchema = z.enum(["message_count", "hotness", "recency", "liked", "random"]);
  export type Ranking = z.infer<typeof rankingSchema>;

  export const getPageOptionsSchema = z.object({
    page: z.number().nonnegative(),
    pageSize: z.number().nonnegative(),
    ranking: rankingSchema,
    order: orderSchema,
    filter: z.object({
      creator: z.string().optional(),
      externalCreator: z.string().optional(),
      period: periodSchema,
      nsfwOK: z.boolean(),
      privateOK: z.boolean().optional(),
      searchText: z.string().max(256),
      tags: z.array(z.string()).optional(),
      blacklistedTags: z.array(z.string()).optional()
    })
  });

  export type GetPageOptions = z.infer<typeof getPageOptionsSchema>;
  export interface GetLastPageOptions {
    pageSize: number;
    filter: {
      period: Period;
      nsfwOK: boolean;
      searchText: string;
      tags: string[];
    };
  }

  /**
   * Character information for the update form.
   * Contains extra private fields that only the character creator could see.
   */
  export interface UpdateFormCharacter {
    id: number;
    title: string;
    tagline: string;
    name: string;
    description: string;
    scenario: string;
    greeting: string;
    definitions: string;
    messageExamples: string;
    alternativeGreetings: string[];
    messageCount: number;
    avatar: string;
    banner?: string;
    isNSFW: boolean;
    isForcedNSFW: boolean;
    isPrivate: boolean;
  }

  /**
   * Character information for open graph
   */
  export interface OGCharacter {
    id: number;
    name: string;
    title?: string;
    tagline?: string;
    description: string;
    avatar: string;
    banner?: string;
    isNSFW: boolean;
    messageCount: number;
  }

  export interface ImportCharacter {
    name: string;
    title: string;
    tagline: string;
    description: string;
    greeting: string;
    definitions?: string;
    messageExamples?: string[];
    avatar: File;
    banner?: File;
    externalKind: Enums<"cardexternalkind">;
    externalCreatedBy?: string;
    isPrivate: boolean;
    isNSFW: boolean;
    tags?: string;
  }

  // Schema for the file data
  const fileDataSchema = z.object({
    name: z.string(),
    type: z.string(),
    data: z.string() // base64 encoded string
  });

  // Schema for the imported character data
  export const characterInputSchema = z.object({
    name: z.string().min(characterConfig.nameMinChars).max(characterConfig.nameMaxChars),
    title: z.string().min(characterConfig.titleMinChars).max(characterConfig.titleMaxChars),
    tagline: z.string().min(characterConfig.taglineMinChars).max(characterConfig.taglineMaxChars),
    description: z.string().min(characterConfig.descriptionMinChars).max(characterConfig.descriptionMaxChars),
    greeting: z.string().min(characterConfig.greetingMinChars).max(characterConfig.greetingMaxChars),
    definitions: z
      .string()
      .min(characterConfig.definitionsMinChars)
      .max(characterConfig.definitionsMaxChars)
      .optional(),
    messageExamples: z.array(z.string()).optional(),
    avatar: fileDataSchema,
    banner: fileDataSchema.optional(),
    isNSFW: z.boolean(),
    isPrivate: z.boolean(),
    externalKind: z.enum(["chub", "tavern", "cai", "figgs", "janitor", "spicychat", "unknown"] as const),
    externalCreatedBy: z.string().optional(),
    tags: z.string().optional()
  });

  export const defaultAvatarName = "avatar.webp";

  //SYNC: Keep this in synced with the key of the same name in the config table
  export const unavailableOKThreshold = 1000;

  export interface ImportCharacter {
    name: string;
    title: string;
    tagline: string;
    description: string;
    greeting: string;
    definitions?: string;
    messageExamples?: string[];
    avatar: File;
    banner?: File;
    externalKind: Enums<"cardexternalkind">;
    externalCreatedBy?: string;
    isPrivate: boolean;
    isNSFW: boolean;
    tags?: string;
  }
}
